require('./bootstrap');

// Enable offer click functionality
require('./clickable');

// Jquery UI autocomplete (No need to import all functionality)
require('jquery-ui/ui/widgets/autocomplete');

/**
 * Load Infinite scroll
 * Ref: https://infinite-scroll.com/extras.html
 *
 * @type {{}}
 */
var jQueryBridget = require('jquery-bridget');
var InfiniteScroll = require('infinite-scroll');

// make Infinite Scroll a jQuery plugin
jQueryBridget( 'infiniteScroll', InfiniteScroll, $ );

// Ref: https://github.com/aFarkas/lazysizes
require('./lazysizes.min');

// Ref: https://sulaco-tec.atlassian.net/browse/POR-229
require('./locale');

